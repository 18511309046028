import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SlideTransition from './SlideTransition';
import Alert, {Color} from '@material-ui/lab/Alert';
import {Maybe} from '../../model/type/types';

interface CustomSnackbarProps {
  onClose?: () => void;
  autoHideDuration: number;
}

interface CustomSnackbarState {
  open: boolean;
  severity?: Color;
  msg: string;
}

export default class CustomSnackbar extends React.Component<
  CustomSnackbarProps,
  CustomSnackbarState
> {
  constructor(props: CustomSnackbarProps) {
    super(props);
    this.state = {
      open: false,
      severity: undefined,
      msg: '',
    };
  }

  openSnackbar = (msg: Maybe<string>, severity: Color) => {
    if (msg) {
      this.setState({
        open: true,
        severity: severity,
        msg: msg,
      });
    }
  };

  forceClose = () => {
    const {open} = this.state;
    if (open) {
      this.setState({
        open: false,
      });
    }
  };

  closeSnackbar = () => {
    const {onClose} = this.props;
    if (onClose) {
      //   console.log('ONCLOOSEEEE ')
      onClose();
    }
    this.setState({
      open: false,
    });
  };

  render() {
    const {open, severity, msg} = this.state;
    const {autoHideDuration} = this.props;
    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        TransitionComponent={SlideTransition}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={this.closeSnackbar}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={this.closeSnackbar}
          severity={severity}>
          {msg}
        </Alert>
      </Snackbar>
    );
  }
}
