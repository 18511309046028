import React, {useCallback} from 'react';
import Header from './Header';

const urlLogo =
  'https://wassati.com/wp-content/uploads/2022/03/cropped-logo-wassati-original-colors-2.png';
const title = 'Wassati';
const urlWassati = 'https://wassati.com/';

export default () => {
  const onClick = useCallback(() => {
    window.open(urlWassati);
  }, []);
  return <Header urlLogo={urlLogo} title={title} onClick={onClick} />;
};
