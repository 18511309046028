import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store/store';
import {
  ActionStatus,
  createDefaultActionStatus,
  Maybe,
  StatusRequest,
} from '../../model/type/types';
import {User} from '../../model/type/user';
import {fetchUserBuilder} from '../thunk/fetchUser';
import {fetchUserIpBuilder} from '../thunk/fetchIp';
import {requestResetPasswordBuilder} from '../thunk/requestResetPassword';
import {resetPasswordBuilder} from '../thunk/resetPassword';
import {Color} from '@material-ui/lab/Alert';
import {updateAccountBuilder} from '../thunk/udpateAccount';
import {listUsersBuilder} from '../thunk/listUsers';
import {addUserBuilder} from '../thunk/addUser';
import {removeUserBuilder} from '../thunk/removeUser';

export interface UserState extends User {
  firebaseId: Maybe<string>;
  fetchStatus: StatusRequest;
  requestRestPassword: StatusRequest;
  updateAccount: StatusRequest;
  resetPassword: StatusRequest;
  listUsersAction: ActionStatus;
  addUserAction: ActionStatus;
  removeUserAction: ActionStatus;
  drawerOpen: boolean;
  snackbar: {msg: string; severity: Color} | null;
  userList: User[];
}

const initialState: UserState = {
  firebaseId: null,
  firstName: '',
  lastName: '',
  email: '',
  ip: '',
  isPremium: false,
  isAdmin: false,
  fetchStatus: StatusRequest.IDLE,
  resetPassword: StatusRequest.IDLE,
  requestRestPassword: StatusRequest.IDLE,
  updateAccount: StatusRequest.IDLE,
  listUsersAction: createDefaultActionStatus(),
  addUserAction: createDefaultActionStatus(),
  removeUserAction: createDefaultActionStatus(),
  drawerOpen: false,
  snackbar: null,
  userList: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateFirebaseId: (state, action: PayloadAction<Maybe<string>>) => {
      state.firebaseId = action.payload;
    },
    updateUserData: (state, action: PayloadAction<User>) => {
      if (action.payload) {
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.ip = action.payload.ip;
        state.isPremium = action.payload.isPremium;
        state.isAdmin = action.payload.isAdmin;
        state.firebaseId = null;
      } else {
        const ip = state.ip;
        state = {
          ...initialState,
          ip,
        };
      }
    },
    updateSnackbar(
      state,
      action: PayloadAction<{msg: string; severity: Color} | null>,
    ) {
      state.snackbar = action.payload;
    },
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
    resetAction: (
      state,
      action: PayloadAction<
        'requestRestPassword' | 'resetPassword' | 'updateAccount'
      >,
    ) => {
      state[action.payload] = StatusRequest.IDLE;
    },
    resetActionStatus: (
      state,
      action: PayloadAction<
        'listUsersAction' | 'addUserAction' | 'removeUserAction'
      >,
    ) => {
      state[action.payload] = createDefaultActionStatus();
    },
    updateFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    fetchUserBuilder(builder);
    fetchUserIpBuilder(builder);
    requestResetPasswordBuilder(builder);
    resetPasswordBuilder(builder);
    updateAccountBuilder(builder);
    listUsersBuilder(builder);
    addUserBuilder(builder);
    removeUserBuilder(builder);
  },
});

const {reducer} = userSlice;
export const userActions = userSlice.actions;
export const {updateFirebaseId, updateUserData, reset} = userSlice.actions;
export const selectUserState = (state: RootState) => state.user;
export const selectIsPremiumUser = (state: RootState) => state.user.isPremium;
export const selectIsConnected = (state: RootState) => state.user.firstName;
export const selectFirstName = (state: RootState) => state.user.firstName;
export const selectLastName = (state: RootState) => state.user.lastName;
export const selectEmail = (state: RootState) => state.user.email;
export const selectUserIp = (state: RootState) => state.user.ip;
export const selectUserName = (state: RootState) =>
  state.user.firstName
    ? state.user.firstName + ' ' + state.user.lastName
    : null;

export const selectUserDrawerOpen = (state: RootState) => state.user.drawerOpen;
export const selectRequestResetPasswordStatus = (state: RootState) =>
  state.user.requestRestPassword;
export const selectResetPasswordStatus = (state: RootState) =>
  state.user.resetPassword;
export const selectSnackbar = (state: RootState) => state.user.snackbar;
export const selectUpdateAccount = (state: RootState) =>
  state.user.updateAccount;

export const selectAddUserAction = (state: RootState) =>
  state.user.addUserAction;

export const selectRemoveUserAction = (state: RootState) =>
  state.user.removeUserAction;

export const selectListUsersAction = (state: RootState) =>
  state.user.listUsersAction;

export const selectIsAdmin = (state: RootState) => state.user?.isAdmin;

export const selectUserList = (state: RootState) => state.user.userList;
export default reducer;
