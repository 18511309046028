import React from 'react';
import './Header.scss';

interface HeaderProps {
  urlLogo: string;
  title: string;
  onClick: () => void;
}

export default ({urlLogo, title, onClick}: HeaderProps) => {
  return (
    <nav>
      <div
        className={
          'pageacceuil-header-container pageacceuil-header-container-mobile'
        }>
        <div className={'pageacceuil-header-logo-container'}>
          <img
            className={'pageacceuil-header-logo header-logo-marginleft'}
            alt={'logo-wassati'}
            src={urlLogo}
          />
        </div>
        <div className={'pageacceuil-header-title'}>
          <span className={'pageacceuil-header-title-size'}>
            {title}
            <br />
            <span className={'pageacceuil-header-sous-title-size'}>
              {'Anticipating'}
            </span>
          </span>
        </div>
      </div>
    </nav>
  );
};
