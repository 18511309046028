import socketIo from 'socket.io-client';
import {RequestTheme, ResponseTheme} from './type/commonTypes';
import {ENDPOINT} from '../config';

let socket: SocketIOClient.Socket | null = null;

export const initSocket = () => {
  if (socket) {
    // console.log('socket already init')
    return;
  }

  socket = socketIo(ENDPOINT);
  socket.on('connect', () => {
    // console.log('on connected', )
  });
  socket.on('disconnect', () => {
    // console.log('disconnect', reason)
  });

  return () => {
    // console.log('disconnect attempt')
    socket?.close();
  };
};

let timeOutId: NodeJS.Timeout;
const timeout = 20000;

export const launchScoringRequest = (
  data: Omit<RequestTheme, 'id'>,
  onError: () => void,
) => {
  /*const debug = true;

  const param = !debug
    ? {
        id: socket?.id,
        data: data.data,
        type: data.type,
        idCs: data.idCs,
      }
    : {
        id: socket?.id,
        url: 'www.apple.fr',
        idCs: data.idCs,
      };*/
  const param = {
    id: socket?.id,
    data: data.data,
    type: data.type,
    idCs: data.idCs,
    user: data.user,
    date: data.date,
    lang: data.lang,
    stopWords: data.stopWords,
    ip: data.ip,
  };

  socket?.emit('theme_request', param);
  timeOutId = setTimeout(onError, timeout);
};
/*const wait = (wait: number) =>
  new Promise((resolve) => setTimeout(resolve, wait));*/

export const subsribeToThemes = (
  subscriber: (themes: ResponseTheme) => void,
) => {
  socket?.off('theme_received');
  socket?.on('theme_received', async (themes: ResponseTheme) => {
    clearTimeout(timeOutId);
    // await wait(50000)

    subscriber(themes);
  });
};
