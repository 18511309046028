import React from 'react';
import styles from './icon.module.scss';

const Circle: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={styles.circle}>
      <circle cx="12" cy="12" r="10" />
    </svg>
  );
};

export default Circle;
