import React from 'react';
import styles from './questionReponse.module.scss';
import Paragraphe from '../paragraphe/paragraphe';

interface QuestionReponseProps {
  question: string;
  children: React.ReactNode;
}

const QuestionReponse: React.FC<QuestionReponseProps> = ({
  question,
  children,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.question}> {question} </div>
      <Paragraphe isCenter>{children}</Paragraphe>
    </div>
  );
};

export default QuestionReponse;
