import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';

export const fetchIp = createAsyncThunk<
  string,
  undefined,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/fetchIp',
  async () => {
    const res = await ApiService.GetInstance().getUserIp();
    return res;
  },
  {
    condition: (_, {getState}) =>
      getState().user.fetchStatus !== StatusRequest.LOADING,
  },
);

export const fetchUserIpBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(fetchIp.pending, (state) => {
    if (state.fetchStatus !== StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.LOADING;
    }
  });
  builder.addCase(fetchIp.rejected, (state) => {
    if (state.fetchStatus === StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.FAILED;
    }
  });
  builder.addCase(fetchIp.fulfilled, (state, action) => {
    if (state.fetchStatus === StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.SUCCESS;
      state.ip = action.payload;
    }
  });
};
