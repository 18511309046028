import Routes from '../bootstrap/router/routes';

export interface RoutesManagerOptions {
  baseUrl: string;
}

export interface GetPathOptions {
  params?: Record<string, string | number> | null;
  query?: Record<string, string> | null;
}

export interface GetRouteOptions extends GetPathOptions {
  baseUrl?: string;
}

export interface IRoutesManager {
  getRoute(route: Routes, options?: GetRouteOptions): string;

  getUrl(route: Routes, options?: GetRouteOptions): string;

  findRoute(
    pathname: string,
    params?: Record<string, string | number> | null,
  ): Routes | null;
}

const ltrimSlash = new RegExp('^/+');
const rtrimSlash = new RegExp('/+$');
const trimSlashes = (text: string): string =>
  text.replace(ltrimSlash, '').replace(rtrimSlash, '');

class RoutesManager implements IRoutesManager {
  constructor(private options?: RoutesManagerOptions) {}

  // eslint-disable-next-line class-methods-use-this
  getRoute(route: Routes, options?: GetPathOptions): string {
    let interpolatedRoute = `${route}`;

    if (options?.params) {
      Object.keys(options.params).forEach((param) => {
        interpolatedRoute = interpolatedRoute.replace(
          `:${param}`,
          encodeURIComponent(options.params![param]),
        );
      });
    }

    const searchParams =
      options?.query && Object.keys(options.query).length > 0
        ? `?${new URLSearchParams(options.query).toString()}`
        : '';

    return `${interpolatedRoute}${searchParams}`;
  }

  getUrl(route: Routes, options?: GetRouteOptions): string {
    const base = (options?.baseUrl ?? this.options?.baseUrl ?? '').replace(
      rtrimSlash,
      '',
    );
    const path = this.getRoute(route, options).replace(ltrimSlash, '');

    return `${base}/${path}`;
  }

  findRoute(
    pathname: string,
    params?: Record<string, string | number> | null,
  ): Routes | null {
    const routes: {
      [key: string]: Routes;
    } = Routes;

    const trimmedPathname = trimSlashes(pathname);

    return (
      Object.entries(routes)
        .map(([, route]) => {
          const path = trimSlashes(this.getRoute(route, {params}));

          return path === trimmedPathname ? route : null;
        })
        .find((data) => !!data) ?? null
    );
  }
}

export default RoutesManager;
