import React, {useMemo, useRef, useState} from 'react';
import styles from './appbar.module.scss';
import {useAppDispatch, useAppSelector} from '../../../redux/store/hooks';
import {
  reset,
  selectFirstName,
  selectIsAdmin,
  selectIsConnected,
  selectIsPremiumUser,
  selectLastName,
  userActions,
} from '../../../redux/reducer/userSlice';

import classNames from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {MenuItem, Popover} from '@material-ui/core';

import {useHistory} from 'react-router-dom';
import Routes from '../../../bootstrap/router/routes';
import {signOut} from '../../../bootstrap/firebase/firebase';
import {useResponsive} from '../../../styles/useResponsive';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export interface AppbarProps {
  withSignIn?: boolean;
}
const Appbar: React.FC<AppbarProps> = ({withSignIn = true}) => {
  const isConnected = useAppSelector(selectIsConnected);
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const openMenu = () => {
    if (isConnected) {
      setOpen((open) => !open);
    } else {
      history.push(Routes.Overview);
    }
  };
  const logout = async () => {
    await signOut();
    dispatch(reset());
    history.push(Routes.Overview);
  };
  const ref = useRef<HTMLDivElement>(null);
  const isPremium = useAppSelector(selectIsPremiumUser);
  const isAdmin = useAppSelector(selectIsAdmin);

  const {isDesktop} = useResponsive();
  const toggleDrawer = () => {
    if (isDesktop) {
      return;
    }
    dispatch(userActions.toggleDrawer());
  };

  const rightComponent = useMemo(() => {
    if (isConnected) {
      return (
        <>
          <div className={styles.name}>
            <div className={classNames(styles.name, styles.firstName)}>
              {firstName}
            </div>
            <div className={classNames(styles.name, styles.lastName)}>
              {lastName}
            </div>
          </div>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon
              icon={faUserCircle as IconProp}
              className={styles.icon}
            />
          </div>
        </>
      );
    }
    if (withSignIn) {
      return (
        <div
          className={classNames(styles.name, {
            [styles.nameFreemium]: !isPremium,
          })}>
          Se connecter
        </div>
      );
    }
    return null;
  }, []);
  return (
    <>
      <div className={styles.root}>
        <div className={styles.appbar}>
          {!isDesktop && (
            <div className={styles.iconMenuContainer} onClick={toggleDrawer}>
              <FontAwesomeIcon
                icon={faBars as IconProp}
                className={styles.icon}
              />
            </div>
          )}

          <div className={styles.nameContainer} onClick={openMenu}>
            {rightComponent}
          </div>
          <div style={{marginTop: '60px', marginLeft: '-30px'}} ref={ref} />
        </div>
      </div>
      <Popover
        id={'33'}
        open={open}
        anchorEl={ref.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <MenuItem className={styles.itemMenu} onClick={logout}>
          Se déconnecter
        </MenuItem>
        {isAdmin && (
          <MenuItem
            className={styles.itemMenu}
            onClick={() => {
              history.push(Routes.Admin);
            }}>
            Admin
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

export default Appbar;
