import React from 'react';
import styles from './separator.module.scss';

interface SeparatorProps {
  margin: number;
}

const Separator = ({margin}: SeparatorProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.separator} style={{marginTop: `${margin}px`}} />
    </div>
  );
};

export default Separator;
