import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';
import {
  AddUserInput,
  UpdateAccountInput,
} from '../../model/service/interface/IApiService';

export const addUser = createAsyncThunk<
  void,
  AddUserInput,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/addUser',
  async (input) => {
    const token = await getToken();

    if (!token) {
      throw Error('token is null');
    }

    const result = await ApiService.GetInstance().addUser(input, token);
    if (!result.ok) {
      throw Error(result.msg);
    }
  },
  {
    condition: (_, {getState}) =>
      getState().user.addUserAction.status !== StatusRequest.LOADING,
  },
);

export const addUserBuilder = (builder: ActionReducerMapBuilder<UserState>) => {
  builder.addCase(addUser.pending, (state) => {
    if (state.addUserAction.status !== StatusRequest.LOADING) {
      state.addUserAction.status = StatusRequest.LOADING;
      state.addUserAction.error = undefined;
    }
  });
  builder.addCase(addUser.rejected, (state, action) => {
    if (state.addUserAction.status === StatusRequest.LOADING) {
      state.addUserAction.status = StatusRequest.FAILED;
    }
  });
  builder.addCase(addUser.fulfilled, (state, action) => {
    if (state.addUserAction.status === StatusRequest.LOADING) {
      state.addUserAction.status = StatusRequest.SUCCESS;
      state.addUserAction.error = undefined;
    }
  });
};
