import React, {useEffect, useRef} from 'react';
import Title, {TitleVariant} from '../../components/title/title';
import QuestionReponse from '../../components/questionReponse/questionReponse';
import styles from './enSavoirPlus.module.scss';
import Paragraphe from '../../components/paragraphe/paragraphe';
import Separator from '../../components/separator/separator';
import {useHistory, useLocation} from 'react-router-dom';
import BodyLayout from '../../components/bodyLayout/bodyLayout';
import routesManager from '../../../bootstrap/router/manager';
import Routes from '../../../bootstrap/router/routes';

export enum EnSavoirPlusSection {
  NosData = 'NosData',
  ODD = 'ODD',
  Aligning = 'Aligning',
  Anticipating = 'Anticipating',
  NosAlgos = 'NosAlgos',
}

type ElementOffset = {
  left: number;
  top: number;
};

const Part: React.FC<{children: React.ReactNode; withSeparator?: boolean}> = ({
  children,
  withSeparator = true,
}) => {
  return (
    <div className={styles.partRoot}>
      {children}
      {withSeparator && <Separator margin={80} />}
    </div>
  );
};
const EnSavoirPlus: React.FC = () => {
  const datasRef = useRef<HTMLDivElement>(null);
  const oddRef = useRef<HTMLDivElement>(null);
  const aligningRef = useRef<HTMLDivElement>(null);
  const anticipatingRef = useRef<HTMLDivElement>(null);
  const nosAlgoref = useRef<HTMLDivElement>(null);

  const loc = useLocation();
  const part = new URLSearchParams(loc.search).get('section');
  useEffect(() => {
    console.log(part);
    executeScroll(part as EnSavoirPlusSection);
  }, [part]);
  const executeScroll = (part: EnSavoirPlusSection) => {
    const offset = () => {
      window.scrollBy(0, -120);
    };
    switch (part) {
      case EnSavoirPlusSection.NosData:
        window.scrollTo(0, 0);
        break;
      case EnSavoirPlusSection.ODD:
        oddRef.current?.scrollIntoView();
        offset();
        break;
      case EnSavoirPlusSection.Aligning:
        aligningRef.current?.scrollIntoView();
        offset();
        break;
      case EnSavoirPlusSection.Anticipating:
        anticipatingRef.current?.scrollIntoView();
        offset();
        break;
      case EnSavoirPlusSection.NosAlgos:
        nosAlgoref.current?.scrollIntoView();
        offset();
        break;
    }
  };

  const history = useHistory();
  const onClick = () => {
    history.push(routesManager.getRoute(Routes.AnticipatingHome));
  };
  return (
    <BodyLayout>
      <Part>
        <div ref={datasRef} />
        <Title variant={TitleVariant.h1}>Nos datas</Title>
        <Paragraphe>
          Nous utilisons des données issues de{' '}
          <strong>réseaux sociaux d’engagement.</strong>
        </Paragraphe>
        <QuestionReponse
          question={'Qu’est-ce qu’un réseau social d’engagement ?'}>
          Un réseau social d'engagement est un réseau social regroupant des
          communautés mesurables et visibles autour d'actions concrètes. Il y a
          une dizaine de catégories comme par exemple les sites de pétitions,
          d'économie participative ou de fonctionnalité, de coaching sportif.
          <br />
          Exemple : site de pétitions,
        </QuestionReponse>
        <Paragraphe>
          Nos données sont donc en majorité des données textuelles. Les données
          textuelles font partie des données non structurées, au même titre que
          les formats tels que les images et les vidéos. Selon Dama, "les
          données non structurées sont techniquement tout document, fichier,
          graphique, image, texte, rapport, formulaire, vidéo ou enregistrement
          sonore qui n'a pas été étiqueté ou autrement structuré en lignes et
          colonnes ou en enregistrements." Les défis actuels du traitement du
          langage naturel, ou NLP, proviennent du fait que le langage naturel
          est naturellement ambigu et malheureusement imprécis.
          <br />
          <br />
          Afin de n’utiliser que les données pertinentes vis-à-vis de
          l’engagement, nous décidons de ne prendre en compte que les pétitions
          qui ont reçu un minimum de 50 signatures. Les pétitions de moins de 50
          signatures correspondent en très grande majorité à des pétitions
          humoristiques, pas sérieuses, imaginaires.
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Multilangues et Quantité</Title>
        <Paragraphe>
          Nous comptons plus de 400 000 pétitions en différentes langues. A ce
          séjour le français et l’anglais (US) sont les langues utilisées.
          L’utilisateur peut ainsi décider d’étudier les valeurs des
          consom’acteurs français ou nord-américain.
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Actualisation</Title>
        <Paragraphe>
          Nos données sont actualisées régulièrement afin d’être le plus près
          possible des valeurs actuelles des consomm’acteurs.
        </Paragraphe>
      </Part>
      <Part>
        <div ref={oddRef} />
        <Title variant={TitleVariant.h1}>ODD de l’ONU </Title>
        <Paragraphe>
          <strong>17 objectifs</strong> pour transformer notre monde.
          <br />
          <br />« Les objectifs de développement durable sont un appel à
          l’action de tous les pays – pauvres, riches et à revenu intermédiaire
          – afin de promouvoir la prospérité tout en protégeant la planète. Ils
          nous donnent la marche à suivre pour parvenir à un avenir meilleur et
          plus durable pour tous. Ils répondent aux défis mondiaux auxquels nous
          sommes confrontés, notamment ceux liés à la pauvreté, aux inégalités,
          au climat, à la dégradation de l’environnement, à la prospérité, à la
          paix et à la justice. Les objectifs sont interconnectés et, pour ne
          laisser personne de côté. » ONU
          <br />
          <br />
        </Paragraphe>
        <Paragraphe isCenter>
          1. Pas de pauvreté
          <br /> 2. Faim « zéro » <br /> 3. Bonne santé et bien-être <br /> 4.
          Education de qualité
          <br /> 5. Egalité entre les sexes <br /> 6. Eau propre et
          assainissement <br /> 7. Energie propre et d’un coût abordable <br />{' '}
          8. Travail décent et croissance économique <br /> 9. Industrie,
          innovation et infrastructure <br /> 10. Inégalités réduites <br /> 11.
          Villes et communautés durables <br /> 12. Consommation et production
          responsables <br /> 13. Mesures relatives à la lutte contre les
          changements climatiques <br /> 14. Vie aquatique <br /> 15. Vie
          terrestre <br /> 16. Paix, justice et institutions efficaces <br />{' '}
          17. Partenariat pour la réalisation des objectifs
          <br />
          <br />
          Pour en savoir plus,{' '}
          <a href={'https://www.un.org/sustainabledevelopment/fr'}>
            https://www.un.org/sustainabledevelopment/fr
          </a>
        </Paragraphe>
      </Part>
      <Part>
        <div ref={aligningRef} />
        <Title variant={TitleVariant.h1}>Aligning</Title>
        <Paragraphe>
          Aligning vous aide à <strong>mesurer votre engagement</strong> et être
          en phase avec les valeurs des <strong>« consom'acteurs »</strong>. À
          cet effet, nos algorithmes d’Intelligence Artificielle récupèrent et
          analysent régulièrement des milliers de données pour identifier
          quelles sont les <strong>valeurs</strong> des « consom’acteurs » et
          définir ce qu’on appelle un <strong>contexte sociétal</strong>.
        </Paragraphe>
        <QuestionReponse question={'Qu’est-ce que le contexte sociétal ?'}>
          Pour commencer, il peut être utile de donner un aperçu du contenu. En
          entrée, nous avons construit un corpus composé de pétitions issus du
          web. Nous avons entraîné un modèle et prédit un ensemble de sujets
          issus du contenu de ces pétitions. Comme résultat, nous avons ainsi
          une liste de "sujets" décrits par un ensemble de termes descriptifs.
          Nous les avons étiqueté manuellement en examinant les listes de termes
          et les pétitions contenant des proportions élevées de sujets. Le
          contexte sociétal est l’ensemble de ces sujets/topics, il traduit donc
          les thèmes principaux des données étudiées, ou autrement dit, sachant
          que les données proviennent de réseaux sociaux d’engagement, les
          valeurs des consom’acteurs.
          <br />
          Le contexte sociétal est propre à une zone géographique et à son
          échelle de temps : le contexte sociétal français n’est pas le même que
          celui des états-unis ; le contexte sociétal de 2010-2015 n’est pas le
          même que celui pour la période 2015-2020.
          <br /> Pour en savoir plus sur le contexte sociétal et l’évolution de
          ce dernier, nous vous invitons à utiliser notre produit
          <span className={styles.linkAnticipating} onClick={onClick}>
            {' '}
            Anticipating
          </span>
          .
        </QuestionReponse>
        <Paragraphe>
          Via l’analyse automatique de votre <strong>page d’engagement </strong>
          (si vous avez plusieurs pages d’engagement distinctes, il faut les
          évaluer individuellement), Aligning vous donne l’alignement de votre
          entreprise avec ce contexte sociétal.
        </Paragraphe>
        <QuestionReponse question={'Qu’est-ce qu’une page d’engagement ?'}>
          Une page d’engagement est une page web définie par son url spécifique.
          Elle décrit vos engagements sociaux, sociétaux et/ou environnementaux.
        </QuestionReponse>
        <Paragraphe>
          Cet alignement est donné par la proportion des valeurs sociétales
          transparaissant à travers votre page d’engagement et par notre
          <strong> indice de persuasion</strong>.
        </Paragraphe>
        <QuestionReponse question={'Qu’est-ce que l’indice de persuasion ?'}>
          L’indice de persuasion est un indice qui traduit la force du message
          de la page d’engagement. Il est calculé pour chaque thème en comparant
          le score de proximité de la page d’engagement avec les scores de
          proximité des pétitions du même thème. Ces scores sont calculés par
          nos modèles mathématiques à l’aide d’algorithmes de Machine Learning.
        </QuestionReponse>
        <Paragraphe>
          Aligning offre également la possibilité à l’utilisateur de
          sélectionner son échelle de temps d’étude et ainsi de connaître les
          valeurs des consom’acteurs à différents instants et de suivre
          l’évolution de son engagement. Aligning est donc capable de vous
          fournir, selon l’échelle de date choisie, la position de votre
          engagement (de quelles valeurs il est le plus proche) et son niveau
          d’engagement. Un engagement peut être proche d’une valeur et ne pas
          être engageant (cas d’un indice de persuasion faible).
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Remarques</Title>
        <Paragraphe>
          La page d’engagement peut contenir du contenu non pertinent, notamment
          sous forme d’en-tête, de bas de page, de publicité, qui peut amener à
          biaiser un peu les résultats. Par ailleurs, à ce jour, seul le texte
          présent sur la page d’engagement est analysé, les images (et texte
          contenu dans les images) ne sont pas prises en compte dans notre
          analyse.
          <br />
          <br />
          Wassati n’est évidemment pas maître du contenu des pétitions et de ce
          fait il arrive que des biais s’immiscent dans nos résultats dus à des
          pétitions dont le contenu comporte plus d’éléments perturbateurs que
          pertinents (contenu en différentes langues, énumération de tous les
          signataires et de leur poste, contenu incompréhensible, etc)
        </Paragraphe>
      </Part>
      <Part>
        <div ref={anticipatingRef} />
        <Title variant={TitleVariant.h1}>Anticipating</Title>
        <Paragraphe>
          Anticipating est un outil de compréhension et d’analyse des domaines
          sociétaux. Il permet{' '}
          <strong>
            d’anticiper l’évolution de ces domaines et des tendances
          </strong>{' '}
          de la nouvelle ségmentation des consom’acteurs. Anticipating aide
          également à la communication d’engagement et à se rendre visible par
          les engageants
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Courbes</Title>
        <Paragraphe>
          Anticipating mets ainsi à disposition de l’utilisateur des courbes
          d’évolution des valeurs sociétales dans le temps, et ce selon la
          période et le contexte sociétal choisis.
          <br />
          <br />
          Des courbes représentant l’évolution des thèmes en termes de
          signatures, ce sont les <strong>courbes « ___ »</strong>
          <br />
          <br />
          Les <strong>courbes « ___ » </strong>qui représentent l’évolution en
          fonction du nombre de pétitions créées.
          <br />
          <br />
          Les <strong>courbes « d’engagement »</strong> qui explique les
          tensions qu’a connu et que connaît les domaines sociétaux. A la fois
          le nombre de pétitions et le nombre de signatures sont pris en compte
          pour le calcul des courbes d’engagement / de tensions.
        </Paragraphe>
        <Title variant={TitleVariant.h3}>
          Pour mieux comprendre les domaines sociétaux
        </Title>
        <Paragraphe>
          Dans le but d’aider à la compréhension des valeurs sociétales,
          Anticipating donne accès aux 20 meilleures pétitions par thème et par
          période mais ne sont pas forcément représentatives de la totalité des
          pétitions attachées au thème.
          <br />
          <br />
          De plus, un nuage de mot par thème indique quels sont les termes
          privilégiés par les engageants.
        </Paragraphe>
      </Part>
      <Part withSeparator={false}>
        <div ref={nosAlgoref} />
        <Title variant={TitleVariant.h1}>Nos algorithmes</Title>
        <Title variant={TitleVariant.h2}>Présentation ML</Title>
        <Paragraphe>
          Le <strong>Machine Learning (ML)</strong> se divise généralement en 2
          grandes familles : <br />
          <br />- l’apprentissage supervisé <br />- l’apprentissage
          non-supervisée
          <br /> <br />
          Pour qu'un ordinateur puisse comprendre le langage naturel écrit, il
          doit comprendre les structures symboliques qui se cachent derrière le
          texte. L'utilisation de certaines des techniques de{' '}
          <strong>Natural Language Processing (NLP)</strong> peut permettre à un
          ordinateur de classer un corpus de texte. Le NLP fait partie de l’
          <strong>apprentissage non-supervisée</strong>, il s’agit d’apprendre
          la structure inhérente des données à partir d’analyses et de
          regroupement de données. <br />
          <br />
          Dans les tâches de compréhension et traitement du langage naturel
          (NLP), il existe une hiérarchie de lentilles à travers lesquelles nous
          pouvons extraire du sens - des mots aux phrases, en passant par les
          paragraphes et les documents. Au niveau du document, l'une des façons
          les plus utiles de comprendre un texte est d'analyser ses thèmes. Le
          processus d'apprentissage, de reconnaissance et d'extraction de ces
          thèmes dans une collection de documents est appelé{' '}
          <strong>topic modeling</strong>.<br />
          <br /> Le topic modeling reposent sur une même hypothèse de base :
          chaque <strong>document</strong> est constitué d'un mélange de{' '}
          <strong>thèmes</strong>, et chaque thème est constitué d'une{' '}
          <strong>collection de mots</strong>.<br />
          <br /> En d'autres termes, les modèles sont construits autour de
          l'idée que la sémantique de notre document est en fait régie par des
          variables cachées, ou "latentes", que nous n'observons pas. Par
          conséquent, l'objectif du topic modeling est de découvrir ces
          variables latentes - les thèmes - qui façonnent le sens de notre
          document et de notre corpus.
        </Paragraphe>
        <Title variant={TitleVariant.h2}>Traitement de données</Title>
        <Paragraphe>
          Après avoir recueilli le texte brut de toutes les pétitions, et après
          un nettoyage initial du texte (suppression des tabulations, des sauts
          à la lignes, des espaces supplémentaires, etc.), nous avons appliqué
          les étapes de prétraitement suivantes : <br />
          <br /> ✔ Tokenisation : cela consiste essentiellement à diviser une
          expression, une phrase, un paragraphe ou un document texte entier en
          unités plus petites, comme des mots ou des termes individuels. Chacune
          de ces petites unités est appelée token. <br /> ✔ Suppression de la
          ponctuation. <br /> ✔ Suppression des chiffres, numéros, dates url et
          caractères non latins. <br /> ✔ Mise en minuscule. <br /> ✔
          Suppression des stopwords : à l'aide de 2 listes intégrées, plus des
          tours de suppression manuelle pendant la modélisation. <br /> ✔
          Suppression des mots de moins de 3 lettres. <br /> ✔ Lemmatisation :
          Réduire un mot donné à sa racine. Le mot-racine est appelé lemma.{' '}
          <br /> ✔ Correction des accents et erreurs de frappe courantes. <br />{' '}
          ✔ Fusion des mots écrits de plusieurs manières (exemple :
          covid19/covid-19/covid 19) <br /> ✔ N-grams = ensemble de mots
          co-occurrents : plusieurs tours de détection manuelle effectués durant
          la modélisation.
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Multi-langues</Title>
        <Paragraphe>
          Les étapes de traitement des données sont personnalisées selon la
          langue des données. En effet la tokenisation, les stopwords et les
          ngrams sont différents d’une langue à l’autre. Cela nécessite donc à
          la fois une connaissance métier et une connaissance linguistique pour
          pouvoir obtenir de bons résultats.
          <br /> Pour les stopwords et les ngrams, on comprend assez vite que
          leur contenu dépendra de la langue analysée. Concernant la
          tokenisation, voici un exemple pour illustrer la nécessité de
          différencier le process par langue : l’apostrophe en français est un
          séparateur de mot mais pas en anglais où il est plutôt une
          contraction.
        </Paragraphe>
        <Title variant={TitleVariant.h2}>Modèle ML</Title>
        <Paragraphe>
          Nous utilisons un algorithme appelé TF-iDF pour vectoriser et pondérer
          les mots de nos documents.
          <br />
          TF-IDF signifie "Term Frequency - Inverse Document Frequency". Il
          s'agit d'une technique permettant de quantifier un mot dans les
          documents. Un poids est alors assigné à chaque mot, celui-ci signifie
          l'importance du mot dans le document et le corpus.
        </Paragraphe>
        <Title variant={TitleVariant.h3}>Comment est calculé ce poids ? </Title>
        <Paragraphe>
          Term Frequency (TF) mesure la fréquence d'un mot dans un document.
          Quant à Document Frequency (DF), il mesure l'importance du document
          dans l'ensemble du corpus. En d'autres termes, DF est le nombre de
          documents dans lesquels le mot est présent.
          <br />
          TF-iDF nous donne ainsi une pondération relative des mots du corpus.
          Un mot très fréquent dans l’ensemble des documents aura donc une
          faible valeur malgré qu’il soit très présent.
          <br />
          <br />
          Afin d’extraire les sujets de notre corpus nous utilisons une
          technique d’exploration de données non-supervisée (tels que NMF ou
          LDA) qui permet de découvrir les relations sous-jacentes entre les
          textes. Cette clusterisation est faite en supprimant les mots qui
          apparaissent dans moins de 0.05 % des pétitions et inversement ceux
          présents dans plus de 75 % des pétitions.
        </Paragraphe>
        <Title variant={TitleVariant.h2}>Calcul des scores </Title>
        <Paragraphe>
          Les scores calculés nous permettent d’identifier l’appartenance d’un
          texte aux différents sujets détectés par nos algorithmes. Voyons cela
          comme un score d’appartenance ou de proximité. <br />
          <br />
          Une approche couramment utilisée pour faire correspondre des documents
          similaires est basée sur le comptage du nombre maximum de mots communs
          entre les documents. Mais cette approche présente un défaut inhérent.
          En effet, plus la taille du document augmente, plus le nombre de mots
          communs a tendance à augmenter, même si les documents traitent de
          sujets différents.
          <br /> Nous utilisons alors la similarité cosinus (cosine similarity).
          Cette métrique est utilisée pour mesurer la similarité des documents,
          indépendamment de leur taille, voyons-la comme étant une forme de
          distance sémantique. Mathématiquement, elle mesure le cosinus de
          l'angle entre deux vecteurs projetés dans un espace multidimensionnel.
          <br /> Nous ajoutons à nos scores des filtres supplémentaires
          personnalisés pour s’assurer de la qualité des résultats.
        </Paragraphe>
        <Title variant={TitleVariant.h2}>Limitations</Title>
        <Paragraphe>
          Nous citons ici quelques exemples qui peuvent justifier le fait
          d’avoir un faible score ou un score incohérent. <br />
          <br /> • Faible présence de mots. <br />• Biais textuels présents
          (en-tête, pied de page, publicités, texte sans valeurs, etc) <br />•
          Usage de mots trop impactants qui sont directement assimilés à un
          thème en particulier (ex : covid, confinement, transport, ligne,
          enfant, ville, etc) <br />
          • Présence de plusieurs langues en même temps. Un contexte sociétal
          étant associé à une unique langue. <br />• Présence d’expression, de
          groupement de mots (ngram) jamais rencontré dans nos données.
        </Paragraphe>
      </Part>
    </BodyLayout>
  );
};

export default EnSavoirPlus;
