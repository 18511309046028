import React, {useState} from 'react';
import styles from './itemExpandable.module.scss';

import ArrowRight from './right_arrow.svg';

// @ts-ignore

import {useCollapse} from '../../../../hook/customHook';

import Collapsible from 'react-collapsible';
import classNames from 'classnames';

interface ItemExpandableProps {
  children: React.ReactNode;
  icon: React.ReactElement;
  title: string;
}

interface HeaderProps {
  isOpen: boolean;
  title: string;
  icon: React.ReactElement;
}

const Header: React.FC<HeaderProps> = ({isOpen, title, icon}) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.selected]: isOpen,
        [styles.noSelected]: !isOpen,
      })}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>{icon}</div>
        <span className={styles.title}> {title} </span>
      </div>
      <div>
        <img
          alt={''}
          className={classNames(styles.iconRight, {
            [styles.iconSelected]: isOpen,
          })}
          src={ArrowRight}
        />
      </div>
    </div>
  );
};
const ItemExpandable: React.FC<ItemExpandableProps> = ({
  title,
  icon,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Collapsible
        onOpening={() => {
          setOpen(true);
        }}
        onClosing={() => {
          setOpen(false);
        }}
        transitionTime={200}
        easing={'ease-out'}
        trigger={<Header title={title} icon={icon} isOpen={isOpen} />}>
        <div className={styles.list}>{children}</div>
      </Collapsible>
    </div>
  );
};

export default ItemExpandable;
