import {Grade, GradeValue, IdCS} from '../../../../../model/type/commonTypes';

export const buildGradeFromApi = (
  grades: Grade[],
): Record<IdCS, GradeValue> => {
  const result: Record<string, GradeValue> = {};
  grades.forEach((grade) => {
    const key = Object.keys(grade)[0] as IdCS;
    result[key] = grade[key];
  });
  return result;
};
