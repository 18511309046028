import {Middleware} from 'redux';

export const loggerMiddleware: Middleware = (store) => (next) => (action) => {
  //  console.log('----logger-----')
  // console.log('current state', store.getState())
  //console.log('DISPATTTCHIIIING', action.type)
  let result = next(action);
  // console.log('next state', JSON.stringify(store.getState()))
  //console.log('-------------')
  return result;
};
