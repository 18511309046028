import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';
import {
  AddUserInput,
  RemoveUserInput,
  UpdateAccountInput,
} from '../../model/service/interface/IApiService';

export const removeUser = createAsyncThunk<
  string,
  RemoveUserInput,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/removeUser',
  async (input) => {
    const token = await getToken();

    if (!token) {
      throw Error('token is null');
    }

    const result = await ApiService.GetInstance().removeUser(input, token);

    if (!result.ok) {
      throw Error("Une erreur s'est produite");
    }
    return input.email;
  },
  {
    condition: (_, {getState}) =>
      getState().user.removeUserAction.status !== StatusRequest.LOADING,
  },
);

export const removeUserBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(removeUser.pending, (state) => {
    if (state.removeUserAction.status !== StatusRequest.LOADING) {
      state.removeUserAction.status = StatusRequest.LOADING;
      state.removeUserAction.error = undefined;
    }
  });
  builder.addCase(removeUser.rejected, (state, action) => {
    if (state.removeUserAction.status === StatusRequest.LOADING) {
      console.log('error ', action);
      state.removeUserAction.status = StatusRequest.FAILED;
    }
  });
  builder.addCase(
    removeUser.fulfilled,
    (state, action: PayloadAction<string>) => {
      if (state.removeUserAction.status === StatusRequest.LOADING) {
        state.removeUserAction.status = StatusRequest.SUCCESS;
        state.removeUserAction.error = undefined;
        state.userList = state.userList.filter(
          (user) => user.email !== action.payload,
        );
      }
    },
  );
};
