import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {Maybe} from '../../model/type/types';

const firebaseConfig = {
  apiKey: 'AIzaSyD794yDz_CMFDCG3klGOyNIwpycIECAGW0',
  authDomain: 'aligning-5a866.firebaseapp.com',
  projectId: 'aligning-5a866',
  storageBucket: 'aligning-5a866.appspot.com',
  messagingSenderId: '335292856756',
  appId: '1:335292856756:web:a3f8237400a65406881ae1',
  measurementId: 'G-PPMT8E5PN1',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export const onAuthStateChanged = (
  callback: (user: firebase.User | null) => void,
) => auth.onAuthStateChanged(callback);

export const signInWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<firebase.auth.UserCredential> => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const createEmailAndPassword = (
  email: string,
  password: string,
): Promise<firebase.auth.UserCredential> =>
  auth.createUserWithEmailAndPassword(email, password);

export const getUserSync = (): firebase.User | null => auth.currentUser;

export const getToken = async (): Promise<Maybe<string>> => {
  try {
    return await auth.currentUser?.getIdToken();
  } catch (e) {
    // console.log('getToken error', e)
    return null;
  }
};

export const signOut = async () => await auth.signOut();
