import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';
import {UpdateAccountInput} from '../../model/service/interface/IApiService';

export const listUsers = createAsyncThunk<
  User[],
  void,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/listUsers',
  async (_, {rejectWithValue}) => {
    const token = await getToken();

    if (!token) {
      throw Error('token is null');
    }

    const result = await ApiService.GetInstance().listUsers(token);
    if (!result.ok) {
      throw Error("Une erreur s'est produite");
    }
    return result.users;
  },
  {
    condition: (_, {getState}) =>
      getState().user.listUsersAction.status !== StatusRequest.LOADING,
  },
);

export const listUsersBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(listUsers.pending, (state) => {
    if (state.listUsersAction.status !== StatusRequest.LOADING) {
      state.listUsersAction.status = StatusRequest.LOADING;
      state.listUsersAction.error = undefined;
    }
  });
  builder.addCase(listUsers.rejected, (state, action) => {
    if (state.listUsersAction.status === StatusRequest.LOADING) {
      state.listUsersAction.status = StatusRequest.FAILED;
      state.listUsersAction.error = action.error.message;
    }
  });
  builder.addCase(
    listUsers.fulfilled,
    (state, action: PayloadAction<User[]>) => {
      if (state.listUsersAction.status === StatusRequest.LOADING) {
        state.listUsersAction.status = StatusRequest.SUCCESS;
        state.userList = action.payload;
      }
    },
  );
};
