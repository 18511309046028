import React, {useMemo} from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import styles from './drawer.module.scss';

import {Box} from '@material-ui/core';

import List from './list/list';

import {useStyles} from './style';
import HeaderContainer from './Header/HeaderContainer';
import {useResponsive} from '../../../styles/useResponsive';
import {useAppDispatch, useAppSelector} from '../../../redux/store/hooks';
import {
  selectUserDrawerOpen,
  userActions,
} from '../../../redux/reducer/userSlice';

interface MiniDrawerProps {
  children: React.ReactNode;
}

export default function MiniDrawer({children}: MiniDrawerProps) {
  const classes = useStyles();

  const {isDesktop} = useResponsive();
  const open = useAppSelector(selectUserDrawerOpen);

  const drawerVariant = isDesktop ? 'permanent' : 'temporary';
  const dispatch = useAppDispatch();

  const toggleDrawer = () => {
    if (isDesktop) {
      return;
    }
    dispatch(userActions.toggleDrawer());
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        id={'drawer'}
        open={open}
        onClose={toggleDrawer}
        variant={drawerVariant}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: true,
          [classes.drawerClose]: false,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: true,
              [classes.drawerClose]: false,
            },
            classes.paperAnchorDockedLeft,
          ),
        }}>
        <Box style={{height: '100%'}}>
          <div className={styles.header}>
            <HeaderContainer />
          </div>

          <div className={styles.drawerContainer}>
            <List />
          </div>
        </Box>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
