import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';
import {UpdateAccountInput} from '../../model/service/interface/IApiService';

export const updateAccount = createAsyncThunk<
  void,
  UpdateAccountInput,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/updateAccount',
  async (input) => {
    const token = await getToken();

    if (!token) {
      throw Error('token is null');
    }

    await ApiService.GetInstance().updateAccount(input, token);
  },
  {
    condition: (_, {getState}) =>
      getState().user.updateAccount !== StatusRequest.LOADING,
  },
);

export const updateAccountBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(updateAccount.pending, (state) => {
    if (state.updateAccount !== StatusRequest.LOADING) {
      state.updateAccount = StatusRequest.LOADING;
    }
  });
  builder.addCase(updateAccount.rejected, (state, action) => {
    if (state.updateAccount === StatusRequest.LOADING) {
      state.updateAccount = StatusRequest.FAILED;
    }
  });
  builder.addCase(updateAccount.fulfilled, (state, action) => {
    if (state.updateAccount === StatusRequest.LOADING) {
      state.updateAccount = StatusRequest.SUCCESS;
    }
  });
};
