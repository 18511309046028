import React, {useEffect} from 'react';
import styles from './bodyLayout.module.scss';
import Appbar from '../appbar/AppBAr';

interface BodyLayoutProps {
  children: React.ReactNode;
}

const BodyLayout: React.FC<BodyLayoutProps> = ({children}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Appbar />
      <div className={styles.root}>
        <div className={styles.textContainer}>{children}</div>
      </div>
    </>
  );
};

export default BodyLayout;
