import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';
import {ResetPaswordInput} from '../../model/service/interface/IApiService';

export const resetPassword = createAsyncThunk<
  void,
  ResetPaswordInput,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/resetPassword',
  async (input) => {
    await ApiService.GetInstance().resetPassword(input);
  },
  {
    condition: (_, {getState}) =>
      getState().user.resetPassword !== StatusRequest.LOADING,
  },
);

export const resetPasswordBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(resetPassword.pending, (state) => {
    if (state.resetPassword !== StatusRequest.LOADING) {
      state.resetPassword = StatusRequest.LOADING;
    }
  });
  builder.addCase(resetPassword.rejected, (state, action) => {
    if (state.resetPassword === StatusRequest.LOADING) {
      state.resetPassword = StatusRequest.FAILED;
    }
  });
  builder.addCase(resetPassword.fulfilled, (state, action) => {
    if (state.resetPassword === StatusRequest.LOADING) {
      state.resetPassword = StatusRequest.SUCCESS;
    }
  });
};
