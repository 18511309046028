import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {color, colorLight} from '../../technical/util/data_text';
import {StatusRequest} from '../../model/type/types';
import {
  BulletItem,
  DataInputType,
  GradeValue,
  IdCS,
  ItemClassement,
  Lang,
  RequestTheme,
  ResponseTheme,
} from '../../model/type/commonTypes';
import {RootState} from '../store/store';
import {isProd} from '../../config';
import {buildGradeFromApi} from '../../view/pages/aligning/grade/feature/buildGradeFromApi';

interface AligningState {
  status: StatusRequest;
  data: string | null;
  type: DataInputType;
  lang: Lang;
  idCs: IdCS;
  error: string | null;
  stopWords: string[];
  contexteSocietal: null | {
    chart: {
      donut: {
        labels: string[];
        series: number[];
      };
      bullet: BulletItem[];
    };
    classement: ItemClassement[];
    grade: Record<IdCS, GradeValue>;
  };
  currentSelected: string | null;
}

const initialState: AligningState = {
  // url: 'https://www.apple.com/fr/environment/',
  //url: 'https://www.stmichel.fr/nos-engagements',
  ///data: 'http://www.apple.fr',
  data: '',
  /*data:
    'https://www.change.org/p/diffusez-les-matchs-des-bleues-%C3%A0-la-t%C3%A9l%C3%A9',*/
  idCs: isProd ? 'FR_CS_C22' : 'FR_CS_C20',
  lang: 'fr_FR',
  type: 'url',
  stopWords: [],
  contexteSocietal: null,
  status: StatusRequest.IDLE,
  error: null,
  currentSelected: null,
};

const aligningSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    updateLang: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
    setCurrentSelected: (state, action: PayloadAction<string>) => {
      state.currentSelected = action.payload;
    },

    setScoresRequestIdle: (state) => {
      state.status = StatusRequest.IDLE;
    },
    scoresRequestPending: (
      state,
      action: PayloadAction<Omit<RequestTheme, 'id'>>,
    ) => {
      if (state.status !== StatusRequest.LOADING) {
        state.data = action.payload.data;
        state.idCs = action.payload.idCs;
        state.type = action.payload.type;
        state.lang = action.payload.lang;
        state.stopWords = action.payload.stopWords;
        state.contexteSocietal = null;
        state.error = null;
        state.status = StatusRequest.LOADING;
      }
    },
    scoresRequestFailed: (state, action: PayloadAction<string>) => {
      state.contexteSocietal = null;
      state.error = action.payload;
      state.status = StatusRequest.FAILED;
    },
    scoresRequestSuccess: (state, action: PayloadAction<ResponseTheme>) => {
      if (state.status === StatusRequest.LOADING) {
        state.status = StatusRequest.SUCCESS;
        state.error = null;
        state.currentSelected = null;
        state.contexteSocietal = {
          classement: action.payload.payload.contexteSocietal.classement,
          chart: {
            donut: {
              labels:
                action.payload.payload.contexteSocietal.chart.donut.labels,
              series:
                action.payload.payload.contexteSocietal.chart.donut.series,
            },
            bullet: action.payload.payload.contexteSocietal.chart.bullet,
          },
          grade: buildGradeFromApi(
            action.payload.payload.contexteSocietal.grade,
          ),
        };
      }
    },
    clearScores: (state) => {
      state.data = null;
      state.contexteSocietal = null;
      state.error = null;
      state.status = StatusRequest.IDLE;
    },
  },
});

const {actions, reducer} = aligningSlice;
export const {
  setScoresRequestIdle,
  scoresRequestPending,
  scoresRequestFailed,
  scoresRequestSuccess,
  clearScores,
  setCurrentSelected,
  updateLang,
} = actions;

export const selectScoreRequestType = (state: RootState) => state.aligning.type;
export const selectScoreRequestData = (state: RootState) => state.aligning.data;
export const selectScoreRequestIdCs = (state: RootState) => state.aligning.idCs;
export const selectScoreRequestContextSocietalLabel = (state: RootState) =>
  state.aligning?.contexteSocietal?.chart?.donut?.labels;
export const selectScoreRequestContextSocietalSeries = (state: RootState) =>
  state.aligning?.contexteSocietal?.chart?.donut?.series;
export const selectScoreRequestContextSocietalClassement = (state: RootState) =>
  state.aligning?.contexteSocietal?.classement;

export const selectScoreRequestContextSocietal = createSelector(
  selectScoreRequestContextSocietalLabel,
  selectScoreRequestContextSocietalSeries,
  (labels, series) => {
    try {
      //   console.log('ret labels '+JSON.stringify(labels))
      // console.log('ret series '+JSON.stringify(series))
      return {
        labels,
        series: series?.map((x) => parseFloat(x.toFixed(2))),
        color: labels?.map((x) => color[x]),
        colorLight: labels?.map((x) => colorLight[x]),
      };
    } catch (e) {
      return null;
    }
  },
);

export const selectScoreRequestContextSocietalForList = createSelector(
  selectScoreRequestContextSocietalLabel,
  selectScoreRequestContextSocietalSeries,
  (labels, series) => {
    try {
      if (!labels || !series) {
        return null;
      }
      type obj = {label: string; score: number; color: string};
      const ret: obj[] = [];

      labels.forEach((currentLabel, index) => {
        ret.push({
          label: currentLabel,
          score: parseFloat(series[index].toFixed(2)),
          color: color[currentLabel],
        });
      });

      return ret;
    } catch (e) {
      return null;
    }
  },
);

export const selectScoreRequestContextSocietalBullet = (state: RootState) =>
  state.aligning?.contexteSocietal?.chart?.bullet;
export const selectScoreRequestStatus = (state: RootState) =>
  state.aligning.status;
export const selectScoreRequestError = (state: RootState) =>
  state.aligning.error;
export const selectAligningLang = (state: RootState) => state.aligning.lang;

export const selectCurrentSelected = (state: RootState) =>
  state.aligning.currentSelected;

export const selectCurrentGrades = (state: RootState) =>
  state.aligning.contexteSocietal?.grade;
export default reducer;
