import React from 'react';
import styles from './title.module.scss';
import classNames from 'classnames';

export enum TitleVariant {
  h1,
  h2,
  h3,
}
interface TitleProps {
  onClick?: () => void;
  id?: string;
  variant: TitleVariant;
  children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({onClick, variant, children}) => {
  return (
    <div
      onClick={onClick}
      className={classNames({
        [styles.h1Container]: variant === TitleVariant.h1,
        [styles.h2Container]: variant === TitleVariant.h2,
        [styles.h3Container]: variant === TitleVariant.h3,
      })}>
      <span
        className={classNames({
          [styles.h1]: variant === TitleVariant.h1,
          [styles.h2]: variant === TitleVariant.h2,
          [styles.h3]: variant === TitleVariant.h3,
        })}>
        {children}
      </span>
    </div>
  );
};

export default Title;
