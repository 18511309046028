import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {IdCS, Lang} from '../../model/type/commonTypes';
import {RootState} from '../store/store';
import {featureAnticipatingEs, isProd} from '../../config';

interface AnticipatingState {
  idCs: IdCS;
  lang: Lang;
}

const initialState: AnticipatingState = {
  idCs: isProd && featureAnticipatingEs ? 'FR_CS_C22' : 'FR_CS_C22',
  lang: 'fr_FR',
};

const anticipatingSlice = createSlice({
  name: 'anticipating',
  initialState,
  reducers: {
    updateContexteSocietal: (state, action: PayloadAction<IdCS>) => {
      state.idCs = action.payload;
    },
    updateLangAnticipating: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
  },
});

export const selectAnticipatingState = (state: RootState) => state.anticipating;
export const selectAnticipatingCS = (state: RootState) =>
  selectAnticipatingState(state).idCs;

export const selectAnticipatingLang = (state: RootState) =>
  selectAnticipatingState(state).lang;

export const {
  updateContexteSocietal,
  updateLangAnticipating,
} = anticipatingSlice.actions;
export default anticipatingSlice.reducer;
