import {ColorTable} from '../../model/type/types';
import {IdCS, Lang} from '../../model/type/commonTypes';
import {Option} from '../../view/components/select/select';
import {featureAnticipatingEs, isProd} from '../../config';

export const buttonlabelSearch = 'Go';
export const placeHolderSearch = "Votre page d'engagement";
export const textIntro =
  'Comment communiquez-vous votre engagement sociétal sur le net ?';

export const feedbackButtonlabel = 'Envoyer';
export const contexteSocietalTitle = 'Contexte sociétal';

export const labelCopyright = '© 2021 | Wassati | Bêta v0.5.1';

export const acceuilButtonLabel = 'Analyser >>>';

export const successFeedback = 'Merci pour votre commentaire !';
export const typesFeedback = [
  {
    value: '0',
    label: 'Remarque',
  },
  {
    value: '1',
    label: 'Bug',
  },
  {
    value: '2',
    label: 'Design',
  },
  {
    value: '3',
    label: 'Score',
  },
  {
    value: '4',
    label: 'Autre',
  },
];
export const getSelectIdCS = (isPremium: boolean, lang: Lang): Option[] => {
  switch (lang) {
    case 'fr_FR':
      if (isProd) {
        return [
          {value: 'FR_CS_C22', label: '2006-2022', disabled: false},
          {
            value: 'FR_CS_C23',
            label: '2006-2023',
            disabled: !isPremium,
          },
          {value: 'FR_CS_1922', label: '2019-2022', disabled: !isPremium},
          {value: 'FR_CS_1518', label: '2015-2018', disabled: !isPremium},
        ];
      }
      return [
        {value: 'FR_CS_C22', label: '2006-2022', disabled: false},
        {value: 'FR_CS_C23', label: '2006-2023', disabled: !isPremium},
        {value: 'FR_CS_1922', label: '2019-2022', disabled: !isPremium},
        {value: 'FR_CS_1518', label: '2015-2018', disabled: !isPremium},
        {value: 'FR_CS_C21', label: '2006-2021', disabled: !isPremium},
        {value: 'FR_CS_1821', label: '2018-2021', disabled: !isPremium},
        {value: 'FR_CS_1417', label: '2014-2017', disabled: !isPremium},
        {value: 'FR_CS_C20', label: '2006-2020', disabled: false},
        {value: 'FR_CS_1720', label: '2017-2020', disabled: !isPremium},
        {value: 'FR_CS_1316', label: '2013-2016', disabled: !isPremium},
      ];
    case 'es_ES':
      return [{value: 'ES_CS_C20', label: '2006-2020', disabled: false}];
    case 'en_US':
      return [{value: 'EN_CS_C21', label: '2006-2021', disabled: !isPremium}];
  }
};

export const getSelectIdCSAnticipating = (
  isPremium: boolean,
  lang: Lang,
): Option[] => {
  if (!featureAnticipatingEs) {
    return [
      {value: 'FR_CS_C20', label: '2006-2020', disabled: false},
      {value: 'FR_CS_1720', label: '2017-2020', disabled: !isPremium},
      {value: 'FR_CS_1316', label: '2013-2016', disabled: !isPremium},
    ];
  }
  switch (lang) {
    case 'fr_FR':
      if (isProd) {
        return [
          {value: 'FR_CS_C22', label: '2006-2022', disabled: false},
          {
            value: 'FR_CS_C23',
            label: '2006-2023',
            disabled: !isPremium,
          },
          {value: 'FR_CS_1922', label: '2019-2022', disabled: !isPremium},
          {value: 'FR_CS_1518', label: '2015-2018', disabled: !isPremium},
        ];
      }
      return [
        {value: 'FR_CS_C22', label: '2006-2022', disabled: false},
        {value: 'FR_CS_C23', label: '2006-2023', disabled: !isPremium},
        {value: 'FR_CS_1922', label: '2019-2022', disabled: !isPremium},
        {value: 'FR_CS_1518', label: '2015-2018', disabled: !isPremium},
        {value: 'FR_CS_C21', label: '2006-2021', disabled: !isPremium},
        {value: 'FR_CS_1821', label: '2018-2021', disabled: !isPremium},
        {value: 'FR_CS_1417', label: '2014-2017', disabled: !isPremium},
        {value: 'FR_CS_C20', label: '2006-2020', disabled: false},
        {value: 'FR_CS_1720', label: '2017-2020', disabled: !isPremium},
        {value: 'FR_CS_1316', label: '2013-2016', disabled: !isPremium},
      ];
    case 'es_ES':
      return [{value: 'ES_CS_C20', label: '2006-2020', disabled: false}];
    case 'en_US':
      return [{value: 'EN_CS_C21', label: '2006-2021', disabled: !isPremium}];
  }
  return [];
};

export const getSelectLang = (isPremium: boolean): Option[] => [
  {value: 'fr_FR', label: 'France', disabled: false},
  {value: 'es_ES', label: 'Espagne', disabled: !isPremium},
  {value: 'en_US', label: 'États-Unis', disabled: !isPremium},
];

export const csLabel: Record<IdCS, string> = {
  FR_CS_1316: '2013-2016',
  FR_CS_1720: '2017-2020',
  FR_CS_C20: '2006-2020',
  FR_CS_C23: '2006-2023',
  ES_CS_C20: '2006-2020',
  FR_CS_1417: '2014-2017',
  FR_CS_1821: '2018-2021',
  FR_CS_C21: '2006-2021',
  EN_CS_C21: '2006-2021',
  FR_CS_1922: '2019-2022',
  FR_CS_C22: '2006-2022',
  FR_CS_1518: '2015-2018',
};

export const translate = {
  Entertainment: 'Divertissement',
  Health: 'Santé',
  Justice: 'Justice',
  Community: 'Vie en collectivité',
  Politics: 'Politique',
  Environment: 'Environnement',
  Family: 'Famille',
  'Responsible Consumption': 'Consommation responsable',
  Education: 'Education',
};

export enum Themes {
  FrProtectionAnimale = 'Protection Animale',
  FrEnvironnement = 'Environnement',
  FrJustice = 'Justice',
  FrPolitique = 'Politique',
  FrSante = 'Santé',
  FrJusticeEco = 'Justice Economique',
  FrEducation = 'Education',
  FrDroitEnfance = 'Droit de l’enfance',
  FrCovid = 'Covid19',
  FrVieLocale = 'Vie Locale',
  FrDroitFemme = 'Droit des femmes',
  FrMobilite = 'Mobilité',
  FrSport = 'Sport',
  FrMedias = 'Médias',
  FrSanteOnde = 'Santé - Précaution Ondes',
  EsSalud = 'salud',
  EsProtectionAnimale = 'protección de animales',
  EsJusticia = 'justicia',
  EsJusticiaEconomica = 'justicia económica',
  EsEducation = 'educación',
  EsPolitica = 'política',
  EsMedioAmbiente = 'medio ambiente',
  EsDerechosDeLaMujer = 'derechos de la mujer',
  EsDerechosDelNino = 'derecho del niño',
  EsMovilidad = 'movilidad',
  EsVidaLocal = 'vida local',
  EsCovid19 = 'covid19',
  EsOcio = 'ocio',
  ESTurismo = 'turismo',
  EnEducation = 'Education',
  EnWomensRight = "Women's right",
  EnAnimalProtection = 'Animal protection',
  EnJustice = 'Justice',
  EnSport = 'Sport',
  EnChildrenRight = "Children's right",
  EnCity = 'City',
  EnPolitics = 'Politics',
  EnEconomicJustice = 'Economic Justice',
  EnCovid = 'Covid',
  EnEnvironment = 'Environment',
  EnEntertainment = 'Entertainment',
}

export const colorLight: ColorTable = {
  [Themes.FrProtectionAnimale]: '#FFD180',
  [Themes.FrEnvironnement]: '#CCFF90',
  [Themes.FrJustice]: '#EA80FC',
  [Themes.FrPolitique]: '#82B1FF',
  [Themes.FrSante]: '#FF8A80',
  [Themes.FrJusticeEco]: '#B388FF',
  [Themes.FrEducation]: '#84FFFF',
  [Themes.FrDroitEnfance]: '#FFFF8D',
  [Themes.FrCovid]: '#F592F1',
  [Themes.FrVieLocale]: '#80D8FF',
  [Themes.FrDroitFemme]: '#FF80AB',
  [Themes.FrMobilite]: '#F4FF81',
  [Themes.FrSport]: '#A8BEC8',
  [Themes.FrMedias]: '#D1AFE8',
  [Themes.FrSanteOnde]: '#BDBDBD',
  [Themes.EsSalud]: '#FF8A80',
  [Themes.EsProtectionAnimale]: '#FFD180',
  [Themes.EsJusticia]: '#EA80FC',
  [Themes.EsJusticiaEconomica]: '#B288FF',
  [Themes.EsEducation]: '#84FFFF',
  [Themes.EsPolitica]: '#82B1FF',
  [Themes.EsMedioAmbiente]: '#CCFF90',
  [Themes.EsDerechosDeLaMujer]: '#FF80AB',
  [Themes.EsDerechosDelNino]: '#FFFF8D',
  [Themes.EsMovilidad]: '#F4FF81',
  [Themes.EsVidaLocal]: '#80D8FF',
  [Themes.EsCovid19]: '#F592F1',
  [Themes.EsOcio]: '#18BEC8',
  [Themes.ESTurismo]: '#3D5AFE',
  [Themes.EnAnimalProtection]: '#FFD180',
  [Themes.EnCovid]: '#F592F1',
  [Themes.EnCity]: '#80D8FF',
  [Themes.EnChildrenRight]: '#FFFF8D',
  [Themes.EnSport]: '#A8BEC8',
  [Themes.EnEconomicJustice]: '#B288FF',
  [Themes.EnEducation]: '#84FFFF',
  [Themes.EnJustice]: '#EA80FC',
  [Themes.EnWomensRight]: '#FF80AB',
  [Themes.EnPolitics]: '#82B1FF',
  [Themes.EnEntertainment]: '#FF9E80',
  [Themes.EnEnvironment]: '#CCFF90',
};

export const color: ColorTable = {
  [Themes.FrProtectionAnimale]: '#FF9100',
  [Themes.FrEnvironnement]: '#76FF03',
  [Themes.FrJustice]: '#D500F9',
  [Themes.FrPolitique]: '#2979FF',
  [Themes.FrSante]: '#FF1744',
  [Themes.FrJusticeEco]: '#651FFF',
  [Themes.FrEducation]: '#00E5FF',
  [Themes.FrDroitEnfance]: '#FFFF00',
  [Themes.FrCovid]: '#D53AEE',
  [Themes.FrVieLocale]: '#00B0FF',
  [Themes.FrDroitFemme]: '#FF4081',
  [Themes.FrMobilite]: '#C6FF00',
  [Themes.FrSport]: '#6192AA',
  [Themes.FrMedias]: '#9F82CE',
  [Themes.FrSanteOnde]: '#757575',
  [Themes.EsSalud]: '#FF1744',
  [Themes.EsProtectionAnimale]: '#FF9100',
  [Themes.EsJusticia]: '#D500F9',
  [Themes.EsJusticiaEconomica]: '#651FFF',
  [Themes.EsEducation]: '#00E5FF',
  [Themes.EsPolitica]: '#2979FF',
  [Themes.EsMedioAmbiente]: '#76FF03',
  [Themes.EsDerechosDeLaMujer]: '#FF4081',
  [Themes.EsDerechosDelNino]: '#FFFF00',
  [Themes.EsMovilidad]: '#C6FF00',
  [Themes.EsVidaLocal]: '#00B0FF',
  [Themes.EsCovid19]: '#D53AEE',
  [Themes.EsOcio]: '#6192AA',
  [Themes.ESTurismo]: '#1A237E',
  [Themes.EnAnimalProtection]: '#FF9100',
  [Themes.EnCovid]: '#D53AEE',
  [Themes.EnCity]: '#00B0FF',
  [Themes.EnChildrenRight]: '#FFFF00',
  [Themes.EnSport]: '#6192AA',
  [Themes.EnEconomicJustice]: '#651FFF',
  [Themes.EnEducation]: '#00E5FF',
  [Themes.EnJustice]: '#D500F9',
  [Themes.EnWomensRight]: '#FF4081',
  [Themes.EnPolitics]: '#2979FF',
  [Themes.EnEntertainment]: '#FF3D00',
  [Themes.EnEnvironment]: '#76FF03',
};

export const colorDescription: ColorTable = {
  [Themes.FrProtectionAnimale]: '#FF9100',
  [Themes.FrEnvironnement]: '#76FF03',
  [Themes.FrJustice]: '#D500F9',
  [Themes.FrPolitique]: '#2979FF',
  [Themes.FrSante]: '#FF1744',
  [Themes.FrJusticeEco]: '#651FFF',
  [Themes.FrEducation]: '#00E5FF',
  [Themes.FrDroitEnfance]: '#FFFF00',
  [Themes.FrCovid]: '#D53AEE',
  [Themes.FrVieLocale]: '#00B0FF',
  [Themes.FrDroitFemme]: '#FF4081',
  [Themes.FrMobilite]: '#C6FF00',
  [Themes.FrSport]: '#6192AA',
  [Themes.FrMedias]: '#9F82CE',
  [Themes.FrSanteOnde]: '#757575',
  [Themes.EsSalud]: '#FF1744',
  [Themes.EsProtectionAnimale]: '#FF9100',
  [Themes.EsJusticia]: '#D500F9',
  [Themes.EsJusticiaEconomica]: '#651FFF',
  [Themes.EsEducation]: '#00E5FF',
  [Themes.EsPolitica]: '#2979FF',
  [Themes.EsMedioAmbiente]: '#76FF03',
  [Themes.EsDerechosDeLaMujer]: '#FF4081',
  [Themes.EsDerechosDelNino]: '#FFFF00',
  [Themes.EsMovilidad]: '#C6FF00',
  [Themes.EsVidaLocal]: '#00B0FF',
  [Themes.EsCovid19]: '#D53AEE',
  [Themes.EsOcio]: '#6192AA',
  [Themes.ESTurismo]: '#1A237E',
  [Themes.EnAnimalProtection]: '#FF9100',
  [Themes.EnCovid]: '#D53AEE',
  [Themes.EnCity]: '#00B0FF',
  [Themes.EnChildrenRight]: '#FFFF00',
  [Themes.EnSport]: '#6192AA',
  [Themes.EnEconomicJustice]: '#651FFF',
  [Themes.EnEducation]: '#00E5FF',
  [Themes.EnJustice]: '#D500F9',
  [Themes.EnWomensRight]: '#FF4081',
  [Themes.EnPolitics]: '#2979FF',
  [Themes.EnEntertainment]: '#FF3D00',
  [Themes.EnEnvironment]: '#76FF03',
};
