import React, {useEffect, useRef} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router-dom';
import MiniDrawer from '../../view/components/drawer/drawer';
import loadable from '@loadable/component';
import Routes from './routes';

import {getToken, getUserSync, onAuthStateChanged} from '../firebase/firebase';
import {
  reset,
  selectSnackbar,
  userActions,
} from '../../redux/reducer/userSlice';
import {useAppDispatch, useAppSelector} from '../../redux/store/hooks';

import {fetchUser} from '../../redux/thunk/fetchUser';
import {fetchIp} from '../../redux/thunk/fetchIp';
import CustomSnackbar from '../../view/commons/CustomSnackbar';
import {useDispatch} from 'react-redux';

const pageAccueil = loadable(
  () => import('../../view/pages/overview/accueil/pageOverview'),
);

const aligningRouter = loadable(() => import('../../view/pages/aligning'));
const anticipatingRouter = loadable(
  () => import('../../view/pages/anticipating'),
);

const pageNosOffres = loadable(
  () => import('../../view/pages/nosOffres/nosOffres'),
);
const pageReleases = loadable(
  () => import('../../view/pages/release/releasePage'),
);
const pageEnSavoirPlus = loadable(
  () => import('../../view/pages/enSavoirPlus/enSavoirPlus'),
);

const overviewRouter = loadable(
  () => import('../../view/pages/overview/overview.router'),
);
const pageOverview = loadable(
  () => import('../../view/pages/overview/accueil/pageOverview'),
);

const pageAccount = loadable(
  () => import('../../view/pages/account/pageAccount'),
);

const adminRouter = loadable(() => import('../../view/pages/admin'));

const Router: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchIp());
  }, [dispatch]);
  useEffect(() => {
    const clean = onAuthStateChanged((user) => {
      if (user) {
        dispatch(fetchUser());
      } else {
        dispatch(reset());
      }
    });

    return () => {
      if (clean) {
        clean();
      }
    };
  }, []);
  const snackbarRef = useRef<CustomSnackbar>(null);
  const snackbar = useAppSelector(selectSnackbar);

  useEffect(() => {
    if (snackbar?.msg && snackbar?.severity) {
      snackbarRef.current?.openSnackbar(snackbar?.msg, snackbar?.severity);
      dispatch(userActions.updateSnackbar(null));
    }
  }, [snackbar?.msg, snackbar?.severity]);

  return (
    <>
      <CustomSnackbar ref={snackbarRef} autoHideDuration={3000} />
      <BrowserRouter>
        <MiniDrawer>
          <Switch>
            <Route path={'/'} component={pageOverview} exact />
            <Route path={Routes.Overview} component={overviewRouter} />
            <Route path={Routes.Account} component={pageAccount} exact />
            <Route path={Routes.Aligning} component={aligningRouter} />
            <Route path={Routes.Anticipating} component={anticipatingRouter} />
            <Route path={Routes.Release} component={pageReleases} exact />
            <Route path={Routes.NosOffres} component={pageNosOffres} exact />
            <Route
              path={Routes.EnSavoirPlus}
              component={pageEnSavoirPlus}
              exact
            />
            <Route path={Routes.Admin} component={adminRouter} />
          </Switch>
        </MiniDrawer>
      </BrowserRouter>
    </>
  );
};

export default Router;
