enum Routes {
  Overview = '/overview',
  OverviewRequestResetPassword = '/overview/requestResetPassword',
  OverviewResetPassword = '/overview/resetPassword/:token/:emailToken',
  Account = '/account',
  Aligning = '/aligning',
  AligningScoring = '/aligning/scoring',
  AligningGrade = '/aligning/grade',
  Anticipating = '/anticipating',
  AnticipatingHome = '/anticipating/home',
  NosOffres = '/nosOffres',
  EnSavoirPlus = '/enSavoirPlus',
  Release = '/releases',
  Admin = '/admin',
  AdminListUsers = '/admin/listUsers',
  AdminAddUser = '/admin/addUser',
}

export default Routes;
