import React, {useEffect, useRef} from 'react';
import Store from './redux/store/store';
import {Provider, useDispatch} from 'react-redux';

import {initSocket, subsribeToThemes} from './model/socket';
import {
  scoresRequestFailed,
  scoresRequestSuccess,
} from './redux/reducer/aligningSlice';
import './App.scss';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';
import {ResponseTheme} from './model/type/commonTypes';

import Router from './bootstrap/router/router';
import CustomSnackbar from './view/commons/CustomSnackbar';
import {useAppSelector} from './redux/store/hooks';
import {selectSnackbar, userActions} from './redux/reducer/userSlice';

/*

    click logo -> retour page acceuil
    agrandir l'app bar
    snackbar up
    clouds,
    scrollTo when open collapse
    passer const heightContainer = window.innerHeight - 30 dans useEffect

 */
//Store.subscribe(() => console.log('Store ' + JSON.stringify(Store.getState())));

const cleanSocket = initSocket();

const theme = createTheme({
  palette: {
    primary: {
      light: '#ffca28',
      main: '#faa96c',
      dark: '#ff9800',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: 'green',
    },
  },
});

const ReduxSocket = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    subsribeToThemes((themes: ResponseTheme) => {
      try {
        if (themes.error.status) {
          dispatch(scoresRequestFailed(themes.error.message!));
          return;
        }

        dispatch(scoresRequestSuccess(themes));
      } catch (e: any) {
        //  console.log('error  '+e)
        dispatch(scoresRequestFailed(e.toString()));
      }
    });
  }, [dispatch]);
  return null;
};

export default () => {
  useEffect(() => {
    return cleanSocket;
  }, []);

  return (
    <Provider store={Store}>
      <ReduxSocket />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  );
};
