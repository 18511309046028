import IApiService, {
  AddUserInput,
  RemoveUserInput,
  ResetPaswordInput,
  UpdateAccountInput,
} from '../interface/IApiService';
import axios, {AxiosRequestConfig} from 'axios';
import {Maybe} from '../../type/types';

import {User} from '../../type/user';
import {ENDPOINT} from '../../../config';

class ApiService implements IApiService {
  private static instance: IApiService;

  static GetInstance(): IApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  async sendFeedback(data: object | string): Promise<void> {
    try {
      /*   const url = 'https://aligning.wassati.com/feedback';
      const config: AxiosRequestConfig = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };*/
    } catch (e) {
      // console.log('sendFeeback error', e)
    }
  }

  async getUserInfos(token?: Maybe<string>): Promise<Omit<User, 'ip'>> {
    const url = ENDPOINT + 'user/infos';

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'X-1': token,
      },
    };

    const res = await axios(config);

    return res.data as Omit<User, 'ip'>;
  }

  async getUserIp(): Promise<string> {
    const res = await axios.get('https://api.ipify.org?format=json');
    return res.data.ip;
  }

  async requestResetPassword(email: string): Promise<void> {
    const url = ENDPOINT + 'user/requestResetPassword';
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: {
        email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios(config);
  }

  async resetPassword(input: ResetPaswordInput): Promise<void> {
    const url = ENDPOINT + 'user/resetPassword';
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: {
        ...input,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios(config);
  }

  async updateAccount(input: UpdateAccountInput, token: string): Promise<void> {
    const url = ENDPOINT + 'user/updateAccount';

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: {
        ...input,
      },
      headers: {
        'Content-Type': 'application/json',
        'X-1': token,
      },
    };

    const res = await axios(config);
  }

  async listUsers(token: string): Promise<{ok: boolean; users: User[]}> {
    const url = ENDPOINT + 'admin/listUsers';
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'X-1': token,
      },
    };

    const res = await axios(config);
    return res.data;
  }

  async addUser(
    input: AddUserInput,
    token: string,
  ): Promise<{ok: boolean; msg: string}> {
    const url = ENDPOINT + 'admin/addUser';
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: {
        ...input,
      },
      headers: {
        'Content-Type': 'application/json',
        'X-1': token,
      },
    };

    const res = await axios(config);
    return res.data;
  }

  async removeUser(
    input: RemoveUserInput,
    token: string,
  ): Promise<{ok: boolean; msg: string}> {
    const url = ENDPOINT + 'admin/deleteUser';
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      data: {
        ...input,
      },
      headers: {
        'Content-Type': 'application/json',
        'X-1': token,
      },
    };

    const res = await axios(config);
    return res.data;
  }
}

export default ApiService;
