import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';

export const requestResetPassword = createAsyncThunk<
  void,
  {email: string},
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/requestResetPassword',
  async ({email}) => {
    await ApiService.GetInstance().requestResetPassword(email);
  },

  {
    condition: (_, {getState}) =>
      getState().user.requestRestPassword !== StatusRequest.LOADING,
  },
);

export const requestResetPasswordBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(requestResetPassword.pending, (state) => {
    if (state.requestRestPassword !== StatusRequest.LOADING) {
      state.requestRestPassword = StatusRequest.LOADING;
    }
  });
  builder.addCase(requestResetPassword.rejected, (state, action) => {
    if (state.requestRestPassword === StatusRequest.LOADING) {
      state.requestRestPassword = StatusRequest.FAILED;
    }
  });
  builder.addCase(requestResetPassword.fulfilled, (state, action) => {
    if (state.requestRestPassword === StatusRequest.LOADING) {
      state.requestRestPassword = StatusRequest.SUCCESS;
    }
  });
};
