import React from 'react';
import styles from './paragraphe.module.scss';
import classNames from 'classnames';

interface ParagrapheProps {
  children: React.ReactNode;
  isCenter?: boolean;
}

const Paragraphe: React.FC<ParagrapheProps> = ({children, isCenter}) => {
  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.content, {
          [styles.textJustify]: !isCenter,
          [styles.textCenter]: isCenter,
        })}>
        {children}
      </div>
    </div>
  );
};

export default Paragraphe;
