export enum StatusRequest {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type ActionStatus = {
  status: StatusRequest;
  error?: Maybe<string>;
};

export const createDefaultActionStatus = () => ({
  status: StatusRequest.IDLE,
});

export type ColorTable = {[key: string]: string};
export type ScoreDescriptionAnalyseParams = {
  countDominantTopics: number;
  dominantTopic: string;
  scoreDominantTopic: number;
  secondaryTopic: string;
  lastSecondarytopic: string;
  scoreSecondarytopic: number;
  scoreLastSecondarytopic: number;
  rangeScoreSecondarytopics: [number, number];
};
export type ScoreDescriptionClassementParams = {
  dominantTopic: string;
  classement: number;
};

export type Maybe<T> = T | null | undefined;
