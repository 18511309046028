import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from '../../model/type/user';
import {AppDispatch, RootState} from '../store/store';
import ApiService from '../../model/service/impl/ApiService';
import {getToken} from '../../bootstrap/firebase/firebase';
import {UserState} from '../reducer/userSlice';
import {StatusRequest} from '../../model/type/types';

export const fetchUser = createAsyncThunk<
  User,
  undefined,
  {dispatch: AppDispatch; state: RootState; rejectValue: Error}
>(
  'user/fetch',
  async () => {
    const token = await getToken();

    if (!token) {
      throw Error('token is null');
    }

    const res = await ApiService.GetInstance().getUserInfos(token);
    return {
      ip: '',
      email: res.email,
      firstName: res.firstName,
      lastName: res.lastName,
      isPremium: res.isPremium,
      isAdmin: res.isAdmin,
    };
  },
  {
    condition: (_, {getState}) =>
      getState().user.fetchStatus !== StatusRequest.LOADING,
  },
);

export const fetchUserBuilder = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(fetchUser.pending, (state) => {
    if (state.fetchStatus !== StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.LOADING;
    }
  });
  builder.addCase(fetchUser.rejected, (state, action) => {
    if (state.fetchStatus === StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.FAILED;
    }
  });
  builder.addCase(fetchUser.fulfilled, (state, action) => {
    if (state.fetchStatus === StatusRequest.LOADING) {
      state.fetchStatus = StatusRequest.SUCCESS;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.ip = action.payload.ip;
      state.isPremium = action.payload.isPremium;
      state.isAdmin = action.payload.isAdmin;
    }
  });
};
