import React from 'react';
import styles from './item.module.scss';
import classNames from 'classnames';

interface ItemListProps {
  onClick?: () => void;
  icon: React.ReactElement;
  title: string;
  selected?: boolean;
}

const Item: React.FC<ItemListProps> = ({icon, onClick, title, selected}) => {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={classNames(styles.root, {
        [styles.selected]: selected,
        [styles.noSelected]: !selected,
      })}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>{icon}</div>
        <span className={styles.title}> {title} </span>
      </div>
    </div>
  );
};

export default Item;
