import {configureStore} from '@reduxjs/toolkit';
import {loggerMiddleware} from '../middleware/middleware';
import reducer from '../reducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
