import React from 'react';
import styles from './itemInfo.module.scss';

interface ItemInfoProps {
  title: string;
}

const ItemInfo: React.FC<ItemInfoProps> = ({title}) => {
  return (
    <div className={styles.root}>
      <span className={styles.title}> {title} </span>
    </div>
  );
};

export default ItemInfo;
