import React, {useEffect, useState} from 'react';
import styles from './list.module.scss';
import Circle from '../../icon/circle';
import Item from '../item/item';
import ItemInfo from '../item/itemInfo';
import ItemExpandable from '../item/itemExandable';

import Accueil from '../../icon/accueil';
import Aligning from '../../icon/aligning';
import Anticipating from '../../icon/anticipating';
import EnSavoirPlus from '../../icon/enSavoirPlus';
import NosOffres from '../../icon/nosOffres';
import Release from '../../icon/release';
import Routes from '../../../../bootstrap/router/routes';
import routesManager from '../../../../bootstrap/router/manager';
import {useHistory, useLocation} from 'react-router-dom';
import {EnSavoirPlusSection} from '../../../pages/enSavoirPlus/enSavoirPlus';
import {useAppDispatch, useAppSelector} from '../../../../redux/store/hooks';
import {useResponsive} from '../../../../styles/useResponsive';
import {
  selectIsConnected,
  userActions,
} from '../../../../redux/reducer/userSlice';
import Account from '../../icon/account';

const overviewRoutes = [
  '/',
  Routes.Overview,
  Routes.OverviewRequestResetPassword,
];

const List: React.FC = () => {
  const history = useHistory();
  const loc = useLocation();
  const [currentRoute, setCurrentRoute] = useState(
    routesManager.findRoute(window.location.pathname),
  );

  useEffect(() => {
    setCurrentRoute(routesManager.findRoute(loc.pathname));
  }, [loc.pathname]);

  const dispatch = useAppDispatch();
  const {isDesktop} = useResponsive();
  const closeDrawerIfNeeded = () => {
    if (isDesktop) {
      return;
    }
    dispatch(userActions.toggleDrawer());
  };

  const isConnected = useAppSelector(selectIsConnected);

  return (
    <div className={styles.root}>
      <ItemInfo title={''} />
      <Item
        selected={!currentRoute || overviewRoutes.includes(currentRoute ?? '')}
        title={'Accueil'}
        icon={<Accueil />}
        onClick={() => {
          //history.push(routesManager.getRoute(Routes.Admin));
          history.push(routesManager.getRoute(Routes.Overview));
          closeDrawerIfNeeded();
        }}
      />
      {isConnected && (
        <Item
          selected={currentRoute === routesManager.getRoute(Routes.Account)}
          title={'Mon compte'}
          icon={<Account />}
          onClick={() => {
            history.push(routesManager.getRoute(Routes.Account));
            closeDrawerIfNeeded();
          }}
        />
      )}

      <ItemInfo title={'apps'} />
      <Item
        title={'Aligning'}
        selected={
          currentRoute === routesManager.getRoute(Routes.AligningScoring) ||
          currentRoute === routesManager.getRoute(Routes.Aligning)
        }
        icon={<Aligning />}
        onClick={() => {
          history.push(routesManager.getRoute(Routes.AligningScoring));
          closeDrawerIfNeeded();
        }}
      />

      <Item
        selected={
          currentRoute === routesManager.getRoute(Routes.AnticipatingHome)
        }
        title={'Anticipating'}
        icon={<Anticipating />}
        onClick={() => {
          history.push(routesManager.getRoute(Routes.AnticipatingHome));
          closeDrawerIfNeeded();
        }}
      />

      <ItemInfo title={'infos'} />
      <Item
        selected={currentRoute === routesManager.getRoute(Routes.NosOffres)}
        title={'Nos offres'}
        icon={<NosOffres />}
        onClick={() => {
          history.push(routesManager.getRoute(Routes.NosOffres));
          closeDrawerIfNeeded();
        }}
      />
      <ItemExpandable title={'En savoir plus'} icon={<EnSavoirPlus />}>
        <Item
          selected={
            window.location.href ===
            routesManager.getUrl(Routes.EnSavoirPlus, {
              query: {section: EnSavoirPlusSection.NosData},
            })
          }
          title={'Nos Datas'}
          icon={<Circle />}
          onClick={() => {
            history.push(
              routesManager.getRoute(Routes.EnSavoirPlus, {
                query: {section: EnSavoirPlusSection.NosData},
              }),
            );
            closeDrawerIfNeeded();
          }}
        />
        <Item
          title={"ODD de l'ONU"}
          selected={
            window.location.href ===
            routesManager.getUrl(Routes.EnSavoirPlus, {
              query: {section: EnSavoirPlusSection.ODD},
            })
          }
          icon={<Circle />}
          onClick={() => {
            history.push(
              routesManager.getRoute(Routes.EnSavoirPlus, {
                query: {section: EnSavoirPlusSection.ODD},
              }),
            );
            closeDrawerIfNeeded();
          }}
        />
        <Item
          selected={
            window.location.href ===
            routesManager.getUrl(Routes.EnSavoirPlus, {
              query: {section: EnSavoirPlusSection.Aligning},
            })
          }
          title={'Aligning'}
          icon={<Circle />}
          onClick={() => {
            history.push(
              routesManager.getRoute(Routes.EnSavoirPlus, {
                query: {section: EnSavoirPlusSection.Aligning},
              }),
            );
            closeDrawerIfNeeded();
          }}
        />
        <Item
          selected={
            window.location.href ===
            routesManager.getUrl(Routes.EnSavoirPlus, {
              query: {section: EnSavoirPlusSection.Anticipating},
            })
          }
          title={'Anticipating'}
          icon={<Circle />}
          onClick={() => {
            history.push(
              routesManager.getRoute(Routes.EnSavoirPlus, {
                query: {section: EnSavoirPlusSection.Anticipating},
              }),
            );
            closeDrawerIfNeeded();
          }}
        />
        <Item
          selected={
            window.location.href ===
            routesManager.getUrl(Routes.EnSavoirPlus, {
              query: {section: EnSavoirPlusSection.NosAlgos},
            })
          }
          title={'Nos algos'}
          icon={<Circle />}
          onClick={() => {
            history.push(
              routesManager.getRoute(Routes.EnSavoirPlus, {
                query: {section: EnSavoirPlusSection.NosAlgos},
              }),
            );
            closeDrawerIfNeeded();
          }}
        />
      </ItemExpandable>
      <Item
        selected={currentRoute === routesManager.getRoute(Routes.Release)}
        title={'Releases'}
        icon={<Release />}
        onClick={() => {
          history.push(routesManager.getRoute(Routes.Release));
          closeDrawerIfNeeded();
        }}
      />
    </div>
  );
};

export default List;
